import SocialItem from './SocialItem/SocialItem';
import social from '../../../data/social';

const Social = () => {
  const socialItems = social.map((item, index) => {
    return <SocialItem data={item} key={index} />;
  });

  return (
    <div className='social'>
      <ul className='social-list'>{socialItems}</ul>
    </div>
  );
};

export default Social;

