export const items = [
  [
    {
      name: 'Company',
      icon: '',
      link: '/company'
    },
    {
      name: 'About Us',
      icon: '/UserActivity.svg',
      link: '/about'
    },
    {
      name: 'Services',
      icon: '/RemoteManagement.svg',
      link: '/services'
    },
    {
      name: 'Partners',
      icon: '/RemoteAccess.svg',
      link: '/partners'
    },
    {
      name: 'News',
      icon: '/News.svg',
      link: '/news'
    },
    {
      name: 'Blog',
      icon: '/CloudUpload.svg',
      link: '/blog'
    },
    {
      name: 'Contact',
      icon: '/ComplianceTracking.svg',
      link: '/contact'
    }
  ]
];

export const label = 'Company';
