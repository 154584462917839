import Image from 'next/image';

const SocialItem = ({data}) => {
  const {social, url, icon} = data;
  const Icon = props => icon === 'phone' ?
    <Image src='/svg/phone.svg' width={16} height={16} alt='' unoptimized/> :
    <Image src='/svg/inst.svg' width={16} height={16} alt='' unoptimized/>;

  return <li className='social-item'>
    <a href={`${url}`} className={`social-link ${social}`}>
      <Icon/>
    </a>
  </li>;
};

export default SocialItem;

