'use client';

import {useState, createContext, useEffect} from 'react';

export const ThemeContext = createContext({
  theme: 'dark'
});

export function ThemeProvider({children}) {
  const is_client = typeof window !== 'undefined';
  const [theme, setTheme] = useState(null);

  useEffect(() => {
    const theme = window.localStorage.getItem('cylerian-theme') ?? 'dark';
    setTheme(theme);
  }, []);

  const provider = {
    theme,
    themeChange: () => {
      const newTheme = theme === 'dark' ? 'light' : 'dark';
      setTheme(newTheme);
      window.localStorage.setItem('cylerian-theme', newTheme);
    }
  };

  return (
    <ThemeContext.Provider value={provider}>{children}</ThemeContext.Provider>
  );
}
