export const items = [
  [
    {
      name: 'Observability',
      icon: '',
      link: '/observability'
    },
    {
      name: 'Infrastructure',
      icon: '/InfrastructureMonitoring.svg',
      link: '/observability#infrastructure'
    },
    {
      name: 'Network',
      icon: '/NetworkMonitoring.svg',
      link: '/observability#network'
    },
    {
      name: 'Application Logs',
      icon: '/ApplicationMonitoring.svg',
      link: '/observability#application'
    },
    {
      name: 'Distributed Tracing',
      icon: '/Tracing.svg',
      link: '/observability#distributed_tracing'
    },
    {
      name: 'Cloud',
      icon: '/CloudSIEM.svg',
      link: '/observability#cloud_siem'
    },
    {
      name: 'User Activity',
      icon: '/UserActivity.svg',
      link: '/observability#user_activity'
    }
  ]
];

export const label = 'Observability';
