import Link from 'next/link';
import {footerNavData, footerLinksData} from '../../../data/navigation';

const navLinks = footerNavData.map((item, key) => {
  const {title, path} = item;

  return (
    <li key={key} className='footer-navItem'>
      <Link href={path} className='nav-link'>
        {title}
      </Link>
    </li>
  );
});

export const FooterNavigation = () => {
  return (
    <nav className='footer-nav'>
      <ul className='footer-nav-list'>{navLinks}</ul>
    </nav>
  );
};

const footerLinks = footerLinksData.map((item, key) => {
  const {title, path} = item;

  return (
    <li key={key} className='footer-navItem'>
      <Link href={path} className='nav-link'>
        {title}
      </Link>
    </li>
  );
});

export const FooterLinks = () => {
  return <ul className='footer-links'>{footerLinks}</ul>;
};

