const social = [
  {
    social: 'phone',
    url: 'tel:18332957426',
    icon: 'phone'
  },
  {
    social: 'inst',
    url: 'https://www.linkedin.com/company/cylerian',
    icon: 'linkedin'
  }
];

export default social;

