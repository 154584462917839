'use client';

import {motion} from 'framer-motion';
import {ThemeContext} from 'utils/ThemeContext';
import Link from 'next/link';
import {useEffect, useState, useRef, useContext} from 'react';
import Navigation from './Navigation/Navigation';
import headerVariants from 'animation/headerVariants';

const Header = () => {
  const {theme, themeChange} = useContext(ThemeContext);
  const [menuOpen, setMenuOpen] = useState(false);
  const menu = useRef(null);

  const changeTheme = () => {
    themeChange();
  };

  const closeMenu = () => {
    if (menu.current) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    if (window) {
      const listener = window.addEventListener('resize', closeMenu);
      return () => window.removeEventListener('resize', listener);
    }
  }, []);

  const changeMenu = () => {
    setMenuOpen(() => !menuOpen);
  };

  return (
    <motion.header
      ref={menu}
      className={menuOpen ? 'header open' : 'header'}
      initial={'hidden'}
      animate={'visible'}
      transition={{
        duration: 0.7,
        delay: 0.5,
        ease: 'easeInOut'
      }}
      variants={headerVariants}
    >
      <div className='container' style={{position: 'relative'}}>
        <Link href='/' className='logo'/>
        <div className='navigation-container'>
          <Navigation closeMenu={closeMenu} />
        </div>
        <div className='buttons-container'>
          <Link href='/contact' className='btn request-btn'>
            Request Demo
          </Link>
          <button className='light-mode' onClick={changeTheme}></button>
          <button className='burger-btn' onClick={changeMenu}></button>
        </div>
      </div>
    </motion.header>
  );
};

export default Header;

