import {items as platform_items} from 'data/platform_dropdown';
import {items as security_items} from 'data/security_dropdown';
import {items as observability_items} from 'data/observability_dropdown';
import {items as operations_items} from 'data/operations_dropdown';

export const items = [
  platform_items[0],
  security_items[0],
  observability_items[0],
  operations_items[0]
];

export const label = 'Platform';
