import Link from 'next/link';
import {FooterNavigation, FooterLinks} from './FooterNavigation/FooterNavigation';
import Social from './Social/Social';

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='container'>
        <Link href='/' className='logo' />
        <div className='navigation-container'>
          <FooterNavigation />
        </div>
        <Social />
        <div className='line'></div>
        <p className='copyright'>© CYLERIAN. All Rights Reserved.</p>
        <FooterLinks />
      </div>
    </footer>
  );
};

export default Footer;

