export const items = [
  [
    {
      name: 'Platform Features',
      icon: '',
      link: '/platform'
    },
    {
      name: 'Dashboards',
      icon: '/Dashboards.svg',
      link: '/platform#dashboard'
    },
    {
      name: 'Reports',
      icon: '/Reports.svg',
      link: '/platform#reports'
    },
    {
      name: 'Integrations',
      icon: '/Integrations.svg',
      link: '/platform#integrations'
    },
    {
      name: 'Alerts',
      icon: '/Alerts.svg',
      link: '/platform#alerts'
    },
    {
      name: 'Playbooks',
      icon: '/Playbooks.svg',
      link: '/platform#playbooks'
    },
    {
      name: 'Graph Explorer',
      icon: '/GraphExplorer.svg',
      link: '/platform#graphExplorer'
    },
    {
      name: 'Analytics',
      icon: '/Analytics.svg',
      link: '/platform#analytics'
    },
    {
      name: 'Integrated Ticketing',
      icon: '/Built-inTicketing.svg',
      link: '/platform#integratedTicketing'
    }
  ]
];

export const label = 'Platform';
