export const items = [
  [
    {
      name: 'Security',
      icon: '',
      link: '/security'
    },
    {
      name: 'Managed XDR',
      icon: '/ManagedXDR.svg',
      link: '/security#managedXDR'
    },
    {
      name: 'Cloud SIEM',
      icon: '/CloudSIEM.svg',
      link: '/security#cloudSIEM'
    },
    {
      name: 'Asset Inventory',
      icon: '/AssetInventory.svg',
      link: '/security#assetInventory'
    },
    {
      name: 'Continuous Monitoring',
      icon: '/ContinuousMonitoring.svg',
      link: '/security#continuousMonitoring'
    },
    {
      name: 'SOAR',
      icon: '/SOAR.svg',
      link: '/security#SOAR'
    },
    {
      name: 'Incident Response',
      icon: '/IncidentResponse.svg',
      link: '/security#incidentResponse'
    },
    {
      name: 'File Integrity Monitoring',
      icon: '/FileIntegrityMonitoring.svg',
      link: '/security#fileIntegrityMonitoring'
    },
    {
      name: 'Threat Hunting',
      icon: '/ThreatHunting.svg',
      link: '/security#threatHunting'
    },
    {
      name: 'Behavioral Analytics',
      icon: '/BehavioralAnalytics.svg',
      link: '/security#behavioralAnalytics'
    }
  ]
];

export const label = 'Security';
