export const headerNavData = [
  //  { title: "Pricing", path: "/pricing" },
  {dropdown: 'cylerian', title: 'Features', path: '/platform'},
  {title: 'Services', path: '/services'},
  {title: 'Partners', path: '/partners'},
  {dropdown: 'company', title: 'Company', path: '/company'},
  /*
  {title: 'Blog', path: '/blog'},
  {title: 'Security', path: '/security'},
  {title: 'Observability', path: '/observability'},
  {title: 'Operations', path: '/operations'}
  */
];

export const smallHeaderNavData = [
  {dropdown: 'platform', title: 'Platform', path: '/platform'},
  {dropdown: 'security', title: 'Security', path: '/security'},
  {dropdown: 'observability', title: 'Observability', path: '/observability'},
  {dropdown: 'operations', title: 'Operations', path: '/operations'},
  {title: 'Services', path: '/services'},
  {title: 'Partners', path: '/partners'},
  {dropdown: 'company', title: 'Company', path: '/company'},
  /*
  {title: 'Blog', path: '/blog'},
  */
];

export const dropdownNavData = [
  {title: 'Overwiew', path: '/platform'},
  {title: 'Security', path: '/security'},
  {title: 'Observability', path: '/observability'},
  {title: 'Operations', path: '/operations'}
];

export const footerNavData = [
  {title: 'Security', path: '/security'},
  {title: 'Observability', path: '/observability'},
  {title: 'Operations', path: '/operations'},
  {title: 'Сompany', path: '/company'},
  {title: 'Blog', path: '/blog'},
  {title: 'Partners', path: '/partners'}
  //  { title: "Pricing", path: "/pricing" },
];

export const footerLinksData = [
  {title: 'Privacy Policy', path: '/privacy'},
  {title: 'Terms', path: '/terms'}
];

