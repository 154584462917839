export const items = [
  [
    {
      name: 'Operations',
      icon: '',
      link: '/operations'
    },
    {
      name: 'Log Management',
      icon: '/LogManagement.svg',
      link: '/operations#logManagement'
    },
    {
      name: 'Infrastructure Management',
      icon: '/RemoteManagement.svg',
      link: '/operations#infrastructureManagement'
    },
    {
      name: 'Cloud File Storage',
      icon: '/CloudUpload.svg',
      link: '/operations#cloudFileStorage'
    },
    {
      name: 'Compliance Tracking',
      icon: '/ComplianceTracking.svg',
      link: '/operations#complianceTracking'
    },
    {
      name: 'Remote Access',
      icon: '/RemoteAccess.svg',
      link: '/operations#remoteAccess'
    },
    {
      name: 'Live View',
      icon: '/Liveview.svg',
      link: '/operations#liveview'
    }
  ]
];

export const label = 'Operations';
