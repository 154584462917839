'use client';

import {useEffect, useContext} from 'react';
import GoogleAnalytics from '@bradgarropy/next-google-analytics';
import {ThemeContext, ThemeProvider} from 'utils/ThemeContext';

import Layout from 'components/Layout/Layout';

import 'styles/globals.scss';
import 'html/cylerian.scss';
import 'html/responsive.scss';

const NEXT_PUBLIC_GOOGLE_ANALYTICS_ID = 'UA-512942-2';

const Root = ({children}) => {
  const {theme, themeChange} = useContext(ThemeContext);
  if (!theme) {
    return <html lang='en'><body></body></html>;
  }

  return <html lang='en' className={theme}>
    <body>{children}</body>
  </html>;
};

const MyApp = props => {
  const {children, params} = props;

  return <ThemeProvider>
    <Root>
      <Layout>
        <GoogleAnalytics measurementId={NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}/>
        {children}
      </Layout>
    </Root>
  </ThemeProvider>;
};

export default MyApp;
