'use client';

import Link from 'next/link';
import {usePathname} from 'next/navigation';
import {useState, useEffect, useRef} from 'react';

const Dropdown = ({closeMenu, label, items}) => {
  const [isOpen, setOpen] = useState(false);
  const pathname = usePathname();

  const dropdownColumns = items.map((item, index) => {
    const dropdownLinks = items[index].map((linkItem, linkIndex) => {
      const {name, icon, link} = linkItem;
      const imgSrc = `/svg/dropdown${icon}`;
      const linkStyle = {backgroundImage: `url(${imgSrc})`};

      // TODO: figure out how to work around nextjs issues w/ hashes in the current route not showing in pathname or any other available hook

      return (
        <li key={linkIndex} className='navItem dropdownItem'>
          <Link href={link} className={`dropdown-link ${pathname.startsWith(link) && 'dropdown-link-active'}`} onClick={closeMenu}>
            <span className={`dropdown-icon ${linkIndex === 0 && 'dropdown-icon-none'}`} style={linkStyle}></span>
            <span className={`dropdown-link-name ${linkIndex === 0 && 'dropdown-link-subtitle'}`}>{name}</span>
          </Link>
        </li>
      );
    });

    return (
      <div key={index} className='dropdown-column'>
        <div className='dropdown-inner-column'>{dropdownLinks}</div>
      </div>
    );
  });

  const dropdown = useRef(null);
  useEffect(() => {
    const onClick = e => dropdown.current.contains(e.target) || setOpen(false);
    document.addEventListener('click', onClick);
    return () => {
      document.removeEventListener('click', onClick);
    }
  }, []);

  const toggleDropdown = () => setOpen(!isOpen);

  return (
    <div className={`dropdown ${isOpen && 'dropdownOpen'}`} ref={dropdown}>
      <div className='dropdownHeader' onClick={toggleDropdown}>
        <p className='nav-link'>{label}</p>
        <span className={`dropdown-arrow ${isOpen && 'open'}`}></span>
      </div>
      <div className={`dropdownBody ${isOpen && 'open'}`} onClick={toggleDropdown}>
        <div className='dropdown-columns'>{dropdownColumns}</div>
      </div>
    </div>
  );
};

export default Dropdown;

