'use client';

import {useState, useEffect} from 'react';

import Link from 'next/link';
import {usePathname, useRouter} from 'next/navigation';
import {smallHeaderNavData, headerNavData} from 'data/navigation';
import Dropdown from '../Dropdown/Dropdown';

import * as platform from 'data/platform_dropdown';
import * as security from 'data/security_dropdown';
import * as observability from 'data/observability_dropdown';
import * as operations from 'data/operations_dropdown';
import * as company from 'data/company_dropdown';
import * as cylerian from 'data/cylerian_dropdown';

const dropdowns = {
  platform,
  security,
  observability,
  operations,
  company,
  cylerian
};

const Navigation = ({closeMenu}) => {
  const router = useRouter();
  const pathname = usePathname();

  const [data, set_data] = useState(null);

  useEffect(() => {
    const update = () => {
      const new_data = window.innerWidth <= 768 ? smallHeaderNavData : headerNavData;
      set_data(new_data);
    }

    window.addEventListener('resize', update);
    update();
    return () => window.removeEventListener('resize', update);
  }, []);

  const navLinks = (data ?? headerNavData).map((item, key) => {
    const {dropdown, title, path} = item;
    if (dropdown) {
      return <Dropdown key={key} {...dropdowns[dropdown]} closeMenu={closeMenu}/>;
    }
    return <li className='navItem' key={key}>
      <Link href={path} className={`nav-link ${path === '/' ? pathname === '/' && 'active' : pathname.startsWith(path) && 'active'}`} onClick={closeMenu}>
        {title}
      </Link>
    </li>;
  });

  return (
    <nav className='nav'>
      <ul className='nav-list'>
        {navLinks}
        <div className='center-btn'>
          <Link href='/contact' className='btn book-btn' onClick={closeMenu}>
            Book a demo
            <span className='arrow-wrapper'>
              <span className='arrow'></span>
            </span>
          </Link>
        </div>
      </ul>
    </nav>
  );
};

export default Navigation;

